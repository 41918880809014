import { Col, Row, Spinner } from 'reactstrap';
import { useEffect, useState } from 'react';
import GoBack from '../../components/GoBack';
import AssignmentInformation from './accounting-components/new-assignment/AssignmentInfo';
import ClientCompanyDetails from './accounting-components/new-assignment/ClientCompanyDetails';
import EmployeeInfo from './accounting-components/new-assignment/EmployeeInfo';
import RecruiterProfit from './accounting-components/new-assignment/Profits';
import RateInformationCalculator from './accounting-components/new-assignment/RateInfoCalculator';
import WorkSiteLocation from './accounting-components/new-assignment/WorkSiteLocation';
import { GET_BILLING_CONTACT, GET_PLACEMENT_BY_ID } from './gql';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/layout/Loader';
import { formatDateUS } from '../../utils/commonFn';
import RecieverInformationClient from './accounting-components/new-assignment/ClientAccountPayableInfo';
import AcaAffordableCareAct from './accounting-components/new-assignment/AcaAffordableCare';
import { handleAccountNewAssignmentPrint } from './accounting-components/utils';
import moment from 'moment';
import {
  PLACEMENT_CC_CONTACTS,
  PLACEMENT_CONTACT_ON_NEW_ASSIGNMENT,
} from '../candidate-pipeline/placements/gql';
import { IComingState } from '../agreements/interfaces';
import {
  VIEW_ACCOUNTING_DASHBOARD_ROUTE,
  VIEW_PLACEMENT_MANAGEMENT_ROUTE,
} from '../../utils/constant';
import CcListOfContacts from './CcListOfContacts';

const NewAssignment = () => {
  const { id }: any = useParams();

  const [loadingPrintNewAssignmentSheet, setLoadingPrintNewAssignmentSheet] =
    useState<boolean>(false);

  const location = useLocation<IComingState>();
  const comingState: any = location.state;

  const [
    _getPlacement,
    { data: _getPlacementData, loading: _getPlacementLoading },
  ] = useLazyQuery(GET_PLACEMENT_BY_ID);

  const [_getBillingContact, { data: _getBillingContactData }] =
    useLazyQuery(GET_BILLING_CONTACT);

  const [_placementTimeCardContact, { data: _placementTimeCardContactData }] =
    useLazyQuery(PLACEMENT_CONTACT_ON_NEW_ASSIGNMENT);

  const [placementContacts, { data: placementContactsList }] = useLazyQuery(
    PLACEMENT_CC_CONTACTS
  );

  useEffect(() => {
    document.title = 'New Assignment - RealRePP';
    _getPlacement({ variables: { id } });
    _placementTimeCardContact({ variables: { placementId: id } });
    placementContacts({ variables: { placementId: id } });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (_getPlacementData?.getPlacementById?.jobOrder?.id) {
      _getBillingContact({
        variables: {
          jobOrderId: _getPlacementData?.getPlacementById?.jobOrder?.id,
        },
      });
    }

    // eslint-disable-next-line
  }, _getPlacementData);

  const onPrintNewAssignmentSheet = async () => {
    setLoadingPrintNewAssignmentSheet(true);
    await handleAccountNewAssignmentPrint(id);
    setLoadingPrintNewAssignmentSheet(false);
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack
        url={
          comingState?.from === 'placement-management'
            ? `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${id}`
            : `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${id}`
        }
      />

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">New Assignment Sheet</h5>

        <button
          type="button"
          disabled={
            loadingPrintNewAssignmentSheet ||
            !(_getPlacementData && _getBillingContactData)
          }
          onClick={onPrintNewAssignmentSheet}
          className="buttonGenericStyle filledButton"
        >
          {loadingPrintNewAssignmentSheet && (
            <Spinner size="sm" className="mr-2 ml-0" />
          )}
          Print
        </button>
      </div>
      <div>
        {_getPlacementLoading && <Loader />}

        {!_getPlacementLoading && (
          <>
            <EmployeeInfo
              employeeInfo={{
                candidateFirstName:
                  _getPlacementData?.getPlacementById?.candidate?.firstName,
                candidateLastName:
                  _getPlacementData?.getPlacementById?.candidate?.lastName,
                companyName:
                  _getPlacementData?.getPlacementById?.candidate?.companyName,
                positionTitle: _getPlacementData?.getPlacementById?.jobTitle,
                candidateStreet:
                  _getPlacementData?.getPlacementById?.candidateStreet,
                candidateCity:
                  _getPlacementData?.getPlacementById?.candidateCity,
                candidateStateCode:
                  _getPlacementData?.getPlacementById?.candidateStateCode,
                candidateCountryCode:
                  _getPlacementData?.getPlacementById?.candidateCountryCode,
                candidatePhone:
                  _getPlacementData?.getPlacementById?.candidatePhone,
                candidateHomePhone:
                  _getPlacementData?.getPlacementById?.candidatePhone,
                candidateEmail:
                  _getPlacementData?.getPlacementById?.candidateEmail,
                startDate: formatDateUS(
                  moment(
                    _getPlacementData?.getPlacementById?.startDateString
                  ).format()
                ),
                salary: _getPlacementData?.getPlacementById?.salaryAmount,
                fee: _getPlacementData?.getPlacementById?.totalCommission,
                totalFee: _getPlacementData?.getPlacementById?.totalCommission,
                adjustmentFee:
                  _getPlacementData?.getPlacementById?.adjustmentTotal,
              }}
            />
            <Row>
              <Col lg="6" md="12" sm="12" xs="12">
                <ClientCompanyDetails
                  timecardApprover={{
                    firstName:
                      _placementTimeCardContactData?.placementContact
                        ?.companyContact?.firstName,
                    mi: _placementTimeCardContactData?.placementContact
                      ?.companyContact?.firstName,
                    lastName:
                      _placementTimeCardContactData?.placementContact
                        ?.companyContact?.lastName,
                    streetAddress:
                      _placementTimeCardContactData?.placementContact
                        ?.companyContact?.companyLocation?.streetAddress,
                    city: _placementTimeCardContactData?.placementContact
                      ?.companyContact?.companyLocation?.city,
                    country:
                      _placementTimeCardContactData?.placementContact
                        ?.companyContact?.companyLocation?.country,
                    state:
                      _placementTimeCardContactData?.placementContact
                        ?.companyContact?.companyLocation?.state,
                    zipCode:
                      _placementTimeCardContactData?.placementContact
                        ?.companyContact?.companyLocation?.zipCode,
                    primaryPhone:
                      _getBillingContactData?.billingContact?.contact
                        ?.contactPhone?.length &&
                      _getBillingContactData?.billingContact?.contact?.contactPhone?.find(
                        (item: { isPrimary: boolean }) => item?.isPrimary
                      )?.number,
                    emailAddress:
                      _placementTimeCardContactData?.placementContact?.companyContact?.contactEmail?.find(
                        (item: { isPrimary: boolean }) => item?.isPrimary
                      )?.email,
                  }}
                />
              </Col>
              <Col lg="6" md="12" sm="12" xs="12">
                <RecieverInformationClient
                  billingContactInfo={{
                    firstName:
                      _getBillingContactData?.billingContact?.contact
                        ?.firstName,
                    lastName:
                      _getBillingContactData?.billingContact?.contact?.lastName,
                    address:
                      _getBillingContactData?.billingContact?.contact
                        ?.companyLocation,
                    primaryPhone:
                      _getBillingContactData?.billingContact?.contact
                        ?.contactPhone?.length &&
                      _getBillingContactData?.billingContact?.contact?.contactPhone?.find(
                        (item: { isPrimary: boolean }) => item?.isPrimary
                      )?.number,
                    email:
                      _getBillingContactData?.billingContact?.contact?.contactEmail?.find(
                        (item) => item?.isPrimary
                      )?.email,
                  }}
                />
              </Col>



              {placementContactsList?.placementCCContact?.length ? placementContactsList?.placementCCContact?.map((ccContact, index) => {
                return (
                  <Col lg="6" md="12" sm="12" xs="12">
                    <CcListOfContacts
                      index={index + 1}
                      ccContactInfo={{
                        firstName: ccContact?.companyContact?.firstName,
                        lastName: ccContact?.companyContact?.lastName,
                        address:
                          ccContact?.companyContact?.companyLocation,
                        primaryPhone:
                          ccContact?.companyContact?.contactPhone
                            ?.length &&
                          ccContact?.companyContact?.contactPhone?.find(
                            (item: { isPrimary: boolean }) =>
                              item?.isPrimary
                          )?.number,
                        email:
                          ccContact?.companyContact?.contactEmail?.find(
                            (item) => item?.isPrimary
                          )?.email,
                      }}
                    />
                  </Col>
                )
              }) : ''}


              <Col lg="6" md="12" sm="12" xs="12">
                <AssignmentInformation
                  assignmentInfo={{
                    startDate: formatDateUS(
                      moment(
                        _getPlacementData?.getPlacementById?.startDateString
                      ).format('MM/DD/YYYY')
                    ),
                    estimateEndDate: formatDateUS(
                      moment(
                        _getPlacementData?.getPlacementById?.estimateEndDate
                      ).format('MM/DD/YYYY')
                    ),
                    assignmentDuration:
                      _getPlacementData?.getPlacementById?.assignmentDuration,
                    jobTitle: _getPlacementData?.getPlacementById?.jobTitle,
                    workplaceType:
                      _getPlacementData?.getPlacementById?.jobworkplaceTypes,
                    placementId:
                      _getPlacementData?.getPlacementById?.placementId,
                    supervisorPosition: _getPlacementData?.getPlacementById?.supervisorPosition
                  }}
                />
              </Col>

              <Col lg="6" md="12" sm="12" xs="12">
                <WorkSiteLocation
                  workSiteInfo={{
                    country:
                      _getPlacementData?.getPlacementById?.jobCountryCode,
                    street: _getPlacementData?.getPlacementById?.jobStreet,
                    city: _getPlacementData?.getPlacementById?.jobCity,
                    stateCode:
                      _getPlacementData?.getPlacementById?.jobStateCode,
                    postalCode:
                      _getPlacementData?.getPlacementById?.jobPostalCode,
                  }}
                />
              </Col>

              <Col md="12" sm="12" xs="12">
                <RateInformationCalculator
                  rateInfo={{
                    billRate: _getPlacementData?.getPlacementById?.billRate,
                    payRate: _getPlacementData?.getPlacementById?.payRate,
                    overtimeBillRate:
                      _getPlacementData?.getPlacementById?.overtimeBillRate,
                    markupPercentage:
                      _getPlacementData?.getPlacementById?.markupPercentage,
                    estimatedLessPayrollBurden:
                      _getPlacementData?.getPlacementById
                        ?.estimatedLessPayrollBurden,
                  }}
                />
              </Col>

              <Col md="12" sm="12" xs="12">
                <AcaAffordableCareAct
                  accaAffordableInfo={{
                    assignmentDuration:
                      _getPlacementData?.getPlacementById?.assignmentDuration,
                    estimate: _getPlacementData?.getPlacementById?.billRate,
                    accaCost: _getPlacementData?.getPlacementById?.billRate,
                    peopleTwoFee:
                      _getPlacementData?.getPlacementById?.peopleTwoFee,
                    billRate: _getPlacementData?.getPlacementById?.billRate,
                  }}
                />
              </Col>
            </Row>
            <RecruiterProfit
              assignedDuration={
                _getPlacementData?.getPlacementById?.assignmentDuration
              }
              recruiterPerHourRate={_getPlacementData?.getPlacementById?.estimatedRecruiterProfit}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NewAssignment;
