import { Col, Row } from "reactstrap";

const EmployeeInfo = ({ employeeInfo }) => {
  return (
    <div className="jobOrderRevamp mb-4">
      <h5>Employee Information</h5>

      <Row>
        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>First Name</label>
            <h6>{employeeInfo?.candidateFirstName}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>MI</label>
            <h6>{"GOOGLE"}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Last Name</label>
            <h6>{employeeInfo?.candidateLastName} </h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Street Address</label>
            <h6>{employeeInfo?.candidateStreet}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>City</label>
            <h6>{employeeInfo?.candidateCity}</h6>
          </div>
        </Col>
        <Col lg="6" md="6" sm="12" xs="12">
        {['US',null,undefined,''].includes(employeeInfo?.candidateCountryCode)? (
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>State</label>
            <h6>{employeeInfo?.candidateStateCode}</h6>
          </div>

        ) :null}

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Country Code</label>
            <h6>{employeeInfo?.candidateCountryCode}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Home Phone</label>
            <h6>{employeeInfo?.candidatePhone || "--"}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Cell Phone</label>
            <h6>{employeeInfo?.candidateHomePhone || "--"}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Email Address</label>
            <h6>{employeeInfo?.candidateEmail || "--"}</h6>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeInfo;
