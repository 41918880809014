import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";
import DELETE_ICON from "../../assets/images/delete-icon-menu.svg";
import EDIT_ICON from "../../assets/images/edit-icon-menu.svg";
import EMAIL_ICON from "../../assets/images/email-icon.svg";
import VIEW_ICON from "../../assets/images/view-icon.svg";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import GoBack from "../../components/GoBack";
import InputField from "../../components/layout/InputField";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import { Pagination } from "../../components/layout/Pagination";
import TableComponent from "../../components/layout/TableComponent";
import { ACTIVITY_REPORTS_HEADER, USER_ACTIVITY_REPORT } from "../../constants";
import { ADMIN_ROUTE, COLUMN_INTERFACE_TYPES_FOR_USER_ACTIVITY_REPORT, PAGE_LIMIT } from "../../utils/constant";
import Preview from "../job-order/job-order-components/document-preview/Preview";
import AddActivityReportModal from "./AddActivityReportModal";
import EmailModalActivityReport from "./EmailModalActivityReport";
import { DELETE_RECORD, GET_CC_EMAILS, GET_SIGNED_URL, GET_USER_ACTIVITY_REPORT } from "./gql";

let time = 0
const UserActivityReports = () => {
  const defaultColumnFilters = {
    userSearch: '',
    monthSearch: '',
    yearSearch: '',
    sorting: {
      columnName: '',
      columnValue: '',
    },
  };
  const [getUserActivityReports, { data, loading, refetch }] =
    useLazyQuery(GET_USER_ACTIVITY_REPORT)
  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL);
  const [getCCEmails, { loading: loadingEmail, data: emailData }] = useLazyQuery(GET_CC_EMAILS);

  const [deleteReport, { loading: deleting }] = useMutation(DELETE_RECORD)

  const [columnFilters, setColumnFilters] =
    useState<COLUMN_INTERFACE_TYPES_FOR_USER_ACTIVITY_REPORT>(defaultColumnFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [singleReport, setSingleReport] = useState<{ id: string; month: string; year: string; userId: string, fullName: string; documentLink: string; email?: string }>()
  const [addReportModal, setAddReportModal] = useState(false);
  const [editAble, setEditAble] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [filtering, setFiltering] = useState<boolean>(false)
  const [emailReportModal, setEmailReportModal] = useState<boolean>(false);
  const [viewDocument, setViewDocument] = useState<boolean>()
  const [documentUrl, setDocumentUrl] = useState<string>('')
  const [documentName, setDocumentName] = useState<string>('')
  const { register, setValue } = useForm()
  const _getUserActivityReports = useCallback(async () => {
    if (filtering) {
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        await getUserActivityReports({
          variables: {
            page: currentPage, limit: limit, columnFilters
          }
        })
      }, 1000);
    } else {
      await getUserActivityReports({
        variables: {
          page: currentPage, limit: limit, columnFilters
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limit, columnFilters])
  useEffect(() => {
    _getUserActivityReports()
  }, [_getUserActivityReports])

  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      setFiltering(false)
      const columnName = fieldName.replace('Sort', '');
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      setFiltering(true)
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
    }
    console.log("columnFilter :: ", columnFilters)
  };

  const SignedUrl = useCallback(async (url) => {
    const { data } = await getSignedURL({ variables: { url } })
    const { getSignedUrl } = data || {};
    const { signedUrl } = getSignedUrl || {};
    return signedUrl;
  }, [getSignedURL])

  const viewReport = async (url: string, name: string) => {
    setViewDocument(true)
    setDocumentUrl(await SignedUrl(url))
    setDocumentName(name)
  }


  return (
    <>
      <GoBack url={ADMIN_ROUTE} />

      <div className='w-100 d-flex justify-content-between align-items-center'>
        <h5 className="m-0">{USER_ACTIVITY_REPORT}</h5>
        <div>
          <button
            onClick={() => setAddReportModal(true)}
            className="buttonGenericStyle filledButton">
            Add New Report
          </button>
        </div>
      </div>

      <div className="table-responsive mt-3">
        <TableComponent
          advanceSearching={true}
          handleChange={changeFiled}
          register={register}
          setValue={setValue}
          tableHeaderAdvSeacrh={ACTIVITY_REPORTS_HEADER}>
          {loading ? (
            <tbody>
              <tr>
                <td colSpan={4}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className='placement-table'>
              {data?.userActivityReports?.userActivityReports?.length > 0 ? (
                data?.userActivityReports?.userActivityReports?.map(({ id,
                  month, year, user: { fullName, email }, userId, documentLink
                }, index) => {

                  return (
                    <tr key={index} className="report-table-row">
                      <td >
                        <span className="">
                          {fullName}
                        </span>
                      </td>

                      <td>
                        <span className=''>
                          {moment().month(parseInt(month) - 1).format('MMMM')}
                        </span>
                      </td>

                      <td>
                        <span className=''>
                          {year}
                        </span>
                      </td>

                      <td>
                        <div className="d-flex justify-center gap-14">
                          <Button
                            onClick={() => {
                              setSingleReport({ id, month, year, userId, fullName, documentLink, email })
                              setEmailReportModal(true)
                            }}
                            className="btn bg-transparent p-0 border-0">
                            <img
                              src={EMAIL_ICON}
                              alt="email"
                              width="18px"
                            />
                          </Button>

                          <Button
                            onClick={() => {
                              viewReport(documentLink, documentLink.match(/\/([^\/]+)$/)[1].split('-')[1])
                            }}
                            className="btn bg-transparent p-0 border-0"
                          >
                            <img src={VIEW_ICON} alt="delete" />
                          </Button>


                          <Button
                            onClick={() => {
                              setEditAble(true)
                              setAddReportModal(true)
                              setSingleReport({ id, month, year, userId, fullName, documentLink })
                            }
                            }
                            className="btn bg-transparent p-0 border-0"
                          >
                            <img src={EDIT_ICON} alt="edit" />
                          </Button>

                          <Button
                            onClick={() => {
                              setConfirmDeletion(!confirmDeletion)
                              setSingleReport({ id, month, year, userId, fullName, documentLink })
                            }}
                            className="btn bg-transparent p-0 border-0"

                          >
                            <img src={DELETE_ICON} alt="delete" />
                          </Button>

                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={ACTIVITY_REPORTS_HEADER.length}
                    className="empty-table-td"
                  >
                    <NoDataFound text="No Reports Found!" />
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </TableComponent>
      </div>

      {!loading && !!data?.userActivityReports?.userActivityReports?.length &&
        <div className="users-pagination" >
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }} currentPage={currentPage}
            pageCount={Math.ceil(data?.userActivityReports?.count / limit)}
          />
          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(
                  currentPage * limit,
                  data?.userActivityReports?.count
                )}
                {` of `} {data?.userActivityReports?.count}
              </p>

              <div className="divider"></div>
              <label>Display</label>
              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </ div>
      }
      <AddActivityReportModal
        edit={editAble} activityReport={singleReport}
        isOpen={addReportModal} refetch={refetch}
        toggle={() => {
          setAddReportModal(!addReportModal)
          setEditAble(false)
        }}
      />
      <EmailModalActivityReport
        activityReport={singleReport}
        ccContact={!loadingEmail && emailData?.getUserManagerEmail}
        isOpen={!loadingEmail && emailReportModal} toggle={() => setEmailReportModal(!emailReportModal)} />
      <ConfirmPopUp
        isOpen={confirmDeletion}
        toggle={() => setConfirmDeletion(!confirmDeletion)}
        modalHeading="Delete Record"
        loading={deleting}
        disabled={deleting}
        confirmText="Are you sure you want to delete this record?"
        confirmAction={async () => {
          const activityReport = await deleteReport({ variables: { id: singleReport?.id } })
          if (activityReport?.data?.removeUserActivityReport?.status === 200) {
            toast.success('deleted successfully')
            setConfirmDeletion(!confirmDeletion)
            refetch()
            return
          }
          toast.error("unable to delete")
        }} />
      <Modal
        isOpen={viewDocument}
        toggle={() => setViewDocument(!viewDocument)}
        centered={true}
        size="xl"
        className="revampDialog border-0"
      >
        <div className="pdf-viewer">
          <Preview
            documentUrl={documentUrl}
            documentName={`${documentName}.pdf`}
            isDownload={true}
          />
        </div>
      </Modal>
    </>
  )
}

export default UserActivityReports;