import { useState } from 'react';
import CandidateDetailDrawer from '../../../components/CandidateDetailDrawer';
import Checkbox from '../../../components/Checkbox';
import { countryWiseHeaderLocation } from '../../../utils/helper';

const CandidateSearchItem = ({
  candidate,
  geoLocDistance,
  selectedCandidates,
  setSelectedCandidates,
  setAllSelected,
  key,
}) => {
  const [showCandidateDetails, setShowCandidateDetails] =
    useState<boolean>(false);
  const candidateDetailToggle = () => {
    setShowCandidateDetails(!showCandidateDetails);
  };

  const manageSelection = (e: any) => {
    if (e.target.checked) {
      setSelectedCandidates([...selectedCandidates, candidate]);
    } else {
      const ids = selectedCandidates?.filter(
        (id: any) => id?.id !== candidate?.id
      );
      setSelectedCandidates(ids);
      setAllSelected(false);
    }
  };

  return (
    <>
      <div className="candidate-search-item mb-4" key={key}>
        <div className="bg-whiteseven px-3 py-4 candidate-searchitem-box light-dark">
          <div className="d-flex">
            <div className="candidate-searchitem-checkbox">
              <Checkbox
                parentClass="checkbox-square"
                candidateId={candidate?.id}
                selectedCandidates={selectedCandidates}
                onChange={(e: any) => manageSelection(e)}
              />
            </div>
            <div className="candidate-searchitem-item flex-1 pl-3 w-100">
              <div className="d-flex align-items-start mb-3 justify-content-center">
                <div className="fixed-width text-right">
                  <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                    Name:
                  </h6>
                </div>

                <div className="flex-1 w-100 pl-3">
                  <span
                    className=" text-primary curser-pointer"
                    onClick={() => candidateDetailToggle()}
                  >
                    <p className="text-sm mb-0 text-bluelink font-weight-normal">
                      {candidate?.fullName}
                    </p>
                  </span>

                  <p className="text-sm mb-0 text-blackten font-weight-normal">
                    {countryWiseHeaderLocation(candidate?.countryCode, candidate?.city, candidate?.stateCode)}
                  </p>
                </div>
              </div>

              {candidate && candidate?.jobTitle && (
                <div className="d-flex align-items-center mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Job Title:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <p className="text-sm mb-0 font-weight-normal text-blackten">
                      {candidate?.jobTitle}
                    </p>
                  </div>
                </div>
              )}

              {candidate && geoLocDistance >= 0 && (
                <div className="d-flex align-items-center mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Distance:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <p className="text-sm mb-0 font-weight-normal text-blackten">
                      {geoLocDistance} miles
                    </p>
                  </div>
                </div>
              )}

              {candidate && candidate?.employmentHistory?.length > 0 && (
                <div className="d-flex align-items-start mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Experience:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    {candidate &&
                      candidate?.employmentHistory
                        ?.slice(0, 2)
                        ?.map((experience, index) => {
                          return (
                            <p
                              key={index}
                              className={`text-sm ${candidate?.employmentHistory?.length - 1 ===
                                index
                                ? 'mb-0'
                                : 'mb-1'
                                } font-weight-normal text-blackten`}
                            >
                              {experience?.jobTitle}
                              {' at '}
                              {experience?.companyName}{' '}
                              <span style={{ fontStyle: 'italic' }}>
                                {experience?.startedAt?.substring(0, 4)}
                                {' - '}
                                {experience?.endedAt?.substring(0, 4) ||
                                  'present'}
                              </span>
                            </p>
                          );
                        })}
                  </div>
                </div>
              )}

              {candidate && candidate?.candidateEducation && (
                <div className="d-flex align-items-center mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Education:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <p className="text-sm mb-0 font-weight-normal text-blackten">
                      {candidate?.candidateEducation?.schoolName}
                      {candidate?.candidateEducation?.fieldOfStudy && ', '}
                      {candidate?.candidateEducation?.fieldOfStudy}
                    </p>
                  </div>
                </div>
              )}

              {candidate && candidate?.recruiterOwner && (
                <div className="d-flex align-items-center mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Owner:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <p className="text-sm mb-0 font-weight-normal text-blackten">
                      {`${candidate?.recruiterOwner?.firstName} ${candidate?.recruiterOwner?.lastName}`}
                    </p>
                  </div>
                </div>
              )}

              <div className="d-flex align-items-start">
                <div className="fixed-width text-right">
                  <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                    Contact:
                  </h6>
                </div>

                <div className="flex-1 w-100 pl-3">
                  <p className="text-sm mb-2 font-weight-normal text-bluelink">
                    {candidate?.emails?.length
                      ? candidate?.emails?.find((val) => val?.isPrimary)?.email
                      : 'NA'}
                  </p>
                  <p className="text-sm mb-0 font-weight-normal text-blackten">
                    {candidate?.phones?.length
                      ? candidate?.phones?.find((val) => val?.isPrimary)?.email
                      : 'NA'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CandidateTabsWrapper">
        <CandidateDetailDrawer
          data={candidate}
          isOpen={showCandidateDetails}
          toggle={() => setShowCandidateDetails(false)}
          isEditable={false}
        />
      </div>
    </>
  );
};

export default CandidateSearchItem;
