import { useLazyQuery, useMutation } from "@apollo/client";
import axios from "axios";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import { Badge, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, Spinner } from "reactstrap";
import VERTICAL_DOTS_FILLED from '../../../../assets/images/vertical-dots-filled.svg';
import VERTICAL_DOTS from '../../../../assets/images/vertical-dots.svg';
import { DELETE, RECRUITER_DEALS_HEADER } from "../../../../constants";
import { DealsProps, IRecruiterDeal, IRecruiterDealHistory } from "../../../../interfaces";
import Preview from "../../../../Pages/job-order/job-order-components/document-preview/Preview";
import { formatDateUSWithMoment } from "../../../../utils/commonFn";
import { EDIT, SET_ACTIVE, SET_INACTIVE, VIEW, VIEW_HISTORY, VIEW_PDF } from "../../../../utils/constant";
import { getClassNameForTableBase } from "../../../../utils/helper";
import ConfirmPopUp from "../../../ConfirmPopUp";
import NoDataFound from "../../../layout/NoDataFound";
import { ACTIVATE_DEAL, DELETE_RECRUITER_DEAL, GET_RECRUITER_DEAL_CHANGE_HISTORY, INACTIVATE_DEAL } from "../gql";
import DealsHistoryStepper from "./DealsHistoryStepper";

const Deals: FC<DealsProps> = ({ povRecruiter = false, setRecruiterDeal, setRecruiterDealId, setEditDrawerOpen, refetch, recruiterDeals, toggleViewDetail }) => {
  const [collapse, setCollapse] = useState({});
  const [menu, setMenu] = useState({})
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false)
  const [dealName, setDealName] = useState<string>('')
  const [dealId, setDealId] = useState<string>('')
  const [activateDealPopUp, setActivateDealPopUp] = useState<boolean>(false)
  const [inactivateDealPopUp, setInactivateDealPopUp] = useState<boolean>(false)
  const [viewingDocument, setViewingDocument] = useState<boolean>(false);
  const [pdfUrl, setPDFUrl] = useState<string>();
  const [viewPDF, setViewPDF] = useState<boolean>(false);
  const [dealHistory, setDealHistory] = useState<IRecruiterDealHistory[]>()

  //mutations and Queries
  const [activateDeal, { loading }] = useMutation(ACTIVATE_DEAL, {
    onCompleted: () => {
      toast.success("Recruiter Deal Activated Successfully")
      refetch && refetch()
    },
    onError: () => toast.error("Unable to Activate Deal")
  })

  const [inactivateDeal, { loading: inactivating }] = useMutation(INACTIVATE_DEAL, {
    onCompleted: () => {
      toast.success("Recruiter Deal Deactivated Successfully")
      refetch && refetch()
    },
    onError: () => toast.error("Unable to Deactivate Deal")
  })

  const [deleteDeal, { loading: deleting }] = useMutation(DELETE_RECRUITER_DEAL, {
    onCompleted: () => {
      toast.success("Recruiter Deal Deleted Successfully")
      refetch && refetch()
    },
    onError: () => toast.error("Unable to Delete this Deal")
  })

  const [getDealHistory, { loading: fetchingHistory }] = useLazyQuery(GET_RECRUITER_DEAL_CHANGE_HISTORY, {
    onCompleted: (data) => {
      setDealHistory(data?.getRecruiterDealHistory?.recruiterDealHistory)
    }
  })

  const toggleViewPDFModal = () => setViewPDF(!viewPDF)

  const toggleCollapse = (index: number) => {
    setCollapse({
      [index]: !collapse[index]
    });
  };

  const toggleMenu = (index: number) => {
    setMenu((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const viewDetail = (item: IRecruiterDeal) => {
    setRecruiterDeal && setRecruiterDeal(item)
    setRecruiterDealId && setRecruiterDealId(item?.id)
    const url = new URL(window.location.href);
    url.searchParams.set('view-deal', item?.id);
    window.history.replaceState({}, '', url.toString())
    toggleViewDetail && toggleViewDetail()
  }

  const editDetail = (item: IRecruiterDeal) => {
    setRecruiterDeal && setRecruiterDeal(item)
    setRecruiterDealId && setRecruiterDealId(item?.id)
    setEditDrawerOpen && setEditDrawerOpen(true)
  }

  const clickViewPDF = async (id: string) => {
    try {
      toggleViewPDFModal();
      setViewingDocument(true)
      const apiBaseURL =
        process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

      let response = await axios({
        method: 'get',
        url: `${apiBaseURL}/recruiter-deal/view-pdf/${id}`,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });

      if (response.data) {
        setViewingDocument(false);
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        setPDFUrl(fileURL);
      }
    } catch (error) {
      setViewingDocument(false);
      toast.error('Error while previewing file.');
    }
  };

  return <div className="custom-table">
    <table className="" style={{
      width: "100%",
      borderSpacing: 10,
      borderCollapse: "separate",
    }}>
      <thead className="table-header py-2">
        <tr className="table-row" style={{ borderRadius: 6, boxShadow: "#F7F8F9 0px 0px 0px 1px" }}>
          {RECRUITER_DEALS_HEADER(povRecruiter).map((key) => (
            <th key={key} className="table-header-cell table-header">
              {key.toLocaleUpperCase()}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="table-body">
        {recruiterDeals.map((item, index) => (
          <>
            <tr key={index} onClick={() => {
              if (collapse[index])
                toggleCollapse(index)
            }}
              className={getClassNameForTableBase(item?.status === 'active', index === 0, index === recruiterDeals.length - 1)}>
              <td className="table-cell d-flex align-items-center">
                <div className={item?.status === 'active' ? "" : "deal-name-text"}>
                  {item?.dealName}
                </div>
              </td>
              <td className="table-cell d-flex align-items-center">
                {item?.recruiterTitle}
              </td>
              <td className="table-cell d-flex align-items-center">
                {`${item?.dhBaseRate} / ${item?.contractingBaseRate}`.replaceAll(" %", '%')}
              </td>
              <td className="table-cell d-flex align-items-center">
                {povRecruiter ? formatDateUSWithMoment(item?.dealStartDate!) : formatDateUSWithMoment(item?.createdAt!)}
              </td>
              <td className="table-cell d-flex align-items-center">
                {formatDateUSWithMoment(item?.updatedAt!)}
              </td>
              <td className="table-cell d-flex align-items-center">
                {item?.status === 'active' &&
                  <Badge color='success' className="text-capitalize">
                    {item.status}
                  </Badge>}
              </td>
              <td className="table-cell">
                <div className='d-flex align-items-center'>
                  <span className='ml-1'>
                    <Dropdown
                      isOpen={menu[index]}
                      toggle={() => toggleMenu(index)}  >
                      <DropdownToggle className="menuButton dark-btn">
                        <img className="m-0" alt="menu-icon" src={item?.status === 'active' ? VERTICAL_DOTS_FILLED : VERTICAL_DOTS} />
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true,
                            fn: (data) => {
                              return {
                                ...data,
                                styles: {
                                  ...data.styles,
                                  overflow: "auto",
                                  maxHeight: "100vh",
                                },
                              };
                            },
                          },
                        }}
                        container={document.body}
                        isOpen={menu[index]}
                        toggle={() => toggleMenu(index)}
                      >
                        {!povRecruiter && <>
                          <DropdownItem
                            className="tableMenuItem" onClick={() => viewDetail(item)}>
                            {VIEW}
                          </DropdownItem>
                          <DropdownItem
                            className="tableMenuItem" onClick={() => editDetail(item)}>
                            {EDIT}
                          </DropdownItem>
                          <DropdownItem
                            className="tableMenuItem d-flex" onClick={() => {
                              setDealId(item?.id)
                              setDealName(dealName)
                              if (item?.status === 'active')
                                setInactivateDealPopUp(true)
                              else
                                setActivateDealPopUp(true)

                            }}>
                            {item.status === 'active' ? SET_INACTIVE : SET_ACTIVE}
                          </DropdownItem>

                          <DropdownItem
                            disabled={fetchingHistory}
                            className="tableMenuItem d-flex align-items-center" onClick={() => {
                              getDealHistory({ variables: { dealId: item?.id } })
                              toggleCollapse(index)
                            }}>
                            {VIEW_HISTORY}
                            {fetchingHistory && <Spinner size="sm" />}
                          </DropdownItem>
                        </>}
                        <DropdownItem disabled={viewingDocument}
                          className="tableMenuItem" onClick={() => clickViewPDF(item!.id)}>
                          {VIEW_PDF}
                          {viewingDocument && <Spinner size="sm" />}
                        </DropdownItem>
                        {!povRecruiter && <DropdownItem className="d-flex" style={{ color: "#CC0000", paddingBottom: 8, fontSize: 12 }}
                          onClick={() => {
                            setDealId(item?.id)
                            setDeletePopUp(true)
                          }}>
                          {DELETE}
                        </DropdownItem>}
                      </DropdownMenu>
                    </Dropdown>

                  </span>
                </div >
              </td>
            </tr>
            {!povRecruiter &&
              <Collapse isOpen={collapse[index]} style={{ maxHeight: "45vh", border: 'none', overflow: 'auto' }}>
                <div className="m-3 pb-5">
                  {fetchingHistory && <div className="my-5 loader" />}

                  {!fetchingHistory && !!dealHistory?.length && dealHistory.map((history) => <DealsHistoryStepper dealHistory={history} />)}

                  {!fetchingHistory && !dealHistory?.length && <NoDataFound text="No History" />}

                </div>
              </Collapse>}
          </>
        ))}
      </tbody >
    </table >
    <ConfirmPopUp
      isOpen={deletePopUp}
      toggle={() => setDeletePopUp(!deletePopUp)}
      confirmAction={() => deleteDeal({ variables: { dealId } })}
      confirmText="Are you sure you want to delete this deal?"
      modalHeading="Delete Deal"
      loading={deleting}
      disabled={deleting}
    />

    <ConfirmPopUp
      isOpen={activateDealPopUp}
      toggle={() => setActivateDealPopUp(!activateDealPopUp)}
      confirmAction={() => activateDeal({ variables: { dealId } })}
      confirmText={`Are you sure you want to mark ${dealName} as active?`}
      modalHeading={`Activate ${dealName}`}
      btnText="Yes, Activate"
      loading={loading}
      disabled={loading}
    />

    <ConfirmPopUp
      isOpen={inactivateDealPopUp}
      toggle={() => setInactivateDealPopUp(!inactivateDealPopUp)}
      confirmAction={() => inactivateDeal({ variables: { dealId } })}
      confirmText={`Are you sure you want to mark ${dealName} as deactivated?`}
      modalHeading={`Deactivate ${dealName}`}
      loading={inactivating}
      disabled={inactivating}
      btnText="Yes, Deactivate"
    />

    <Modal
      isOpen={viewPDF}
      toggle={toggleViewPDFModal}
      centered={true}
      size="xl"
      className="revampDialog border-0"
    >
      <div className="pdf-viewer">
        {viewingDocument ? <div className="loader" style={{ marginBlock: 330 }} /> :
          <Preview
            documentUrl={pdfUrl}
            documentName={'recruiter-deal.pdf'}
            isDownload={true}
          />
        }
      </div>
    </Modal>

  </div >

}
export default Deals